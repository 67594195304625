'use client';
import { cubicBezier, motion, Variants } from 'framer-motion';
import { ReactNode } from 'react';
import { useIsVisitedPage } from '@/shared/hooks/useIsVisitedPage';

const variantsPage: Variants = {
  in: {
    opacity: 1,
    willChange: 'auto',
    transition: {
      duration: 0.3,
      delay: 0,
      ease: cubicBezier(0.55, 0.055, 0.675, 0.19),
    },
  },
  out: {
    opacity: 0,
    willChange: 'auto',
    transition: {
      duration: 0.3,
      delay: 0,
      ease: cubicBezier(0.55, 0.055, 0.675, 0.19),
    },
  },
  exit: {
    opacity: 0,
    willChange: 'auto',
    transition: {
      duration: 0.3,
      delay: 0,
      ease: cubicBezier(0.55, 0.055, 0.675, 0.19),
    },
  },
};

const Template = ({ children }: { children: ReactNode }) => {
  useIsVisitedPage();

  return (
    <motion.div
      variants={variantsPage}
      animate={'in'}
      initial={'out'}
      exit={'out'}>
      {children}
    </motion.div>
  );
};

export default Template;
