import { useEffect, useState } from 'react';
import { useLocalizedPathname } from '@/shared/i18n/routing';

export const useIsVisitedPage = (pagePathname?: string) => {
  const pathname = useLocalizedPathname();

  const [isVisited, setIsVisited] = useState(false);

  useEffect(() => {
    let visitedPages: string[];

    try {
      visitedPages = JSON.parse(
        localStorage.getItem('visitedPages') ?? '[]'
      ) as string[];

      if (!Array.isArray(visitedPages)) {
        throw new Error();
      }
    } catch {
      visitedPages = [];
      localStorage.removeItem('visitedPages');
      localStorage.setItem('visitedPages', JSON.stringify(visitedPages));
    }

    if (!visitedPages.includes(pathname)) {
      visitedPages.push(pathname);
      localStorage.setItem('visitedPages', JSON.stringify(visitedPages));
    }

    setIsVisited(pagePathname ? visitedPages.includes(pagePathname) : false);
  }, [pagePathname, pathname]);

  return isVisited;
};
